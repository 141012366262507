import { Turnstile } from '@marsidev/react-turnstile';

export default function Captcha({ action, siteKey }: { action: string; siteKey: string }) {
  return (
    <div className="flex justify-center">
      <Turnstile
        options={{
          action
        }}
        siteKey={siteKey}
      />
    </div>
  );
}
