import ShapeLogo from '~/svgs/ShapeLogo';
import { Strong, Text, TextLink } from '../Catalyst/text';
import { Badge } from '../Catalyst/badge';
import { Field, Label } from '../Catalyst/fieldset';
import clsx from 'clsx';
import { Input } from '../Catalyst/input';
import { Button } from '../Catalyst/button';
import Captcha from '../Captcha';
import { Checkbox, CheckboxField } from '../Catalyst/checkbox';
import { Props, SignType } from './types';

const messages = {
  [SignType.SignIn]: {
    title: 'Welcome back!',
    subTitle: 'Sign in to your account to continue.',
    submit: 'Sign in'
  },
  [SignType.SignUp]: {
    title: 'Never Miss a Beat: Monitor Your Services 24/7',
    subTitle: 'Create your account to continue',
    submit: 'Sign up'
  },
  [SignType.Invitation]: {
    title: 'You have been Invited!',
    subTitle:
      "We're excited to invite you to experience the power of proactive service monitoring with UptimeInsight!",
    submit: 'Accept'
  }
};

const OtpForm = (props: { email: string; errors: Props['errors'] }) => {
  return (
    <>
      <input type="hidden" value={props.email} name="email" />
      <Field className={clsx(props?.errors?.[''] ? 'mt-6' : 'mt-8')}>
        <Label>
          <span className="pr-2">Code</span>
          {props?.errors?.otp ? (
            <Badge color="red" className="text-justify opacity-80">
              ({props.errors.otp.join(', ')})
            </Badge>
          ) : null}
        </Label>
        <Input
          required
          autoFocus
          id="otp"
          type="text"
          name="otp"
          placeholder="123456"
          autoComplete="one-time-code"
          invalid={!!props?.errors?.otp}
        />
      </Field>
    </>
  );
};

const SignInForm = (props: Props) => {
  return (
    <>
      <Field className={clsx(props.errors?.[''] ? 'mt-6' : 'mt-8')}>
        <Label>
          <span className="pr-2">Email</span>
          {props.errors?.email ? (
            <Badge color="red" className="text-justify opacity-80">
              ({props.errors.email.join(', ')})
            </Badge>
          ) : null}
        </Label>
        <Input
          required
          autoFocus
          id="email"
          type="email"
          name="email"
          autoComplete="email"
          placeholder="john.doe@uptimeinsight.com"
          invalid={!!props?.errors?.email}
          defaultValue={props?.defaults?.email}
        />
      </Field>
      <Captcha siteKey={props.siteKey} action="sign-in" />
    </>
  );
};

const SignUpForm = (props: Props) => {
  return (
    <>
      <div
        className={clsx(
          props?.errors?.[''] ? 'mt-6' : 'mt-8',
          'grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4'
        )}
      >
        <Field>
          <Label>
            <span className="pr-2">First Name</span>
            {props?.errors?.firstName ? (
              <Badge color="red" className="text-justify opacity-80">
                ({props.errors.firstName.join(', ')})
              </Badge>
            ) : null}
          </Label>
          <Input
            required
            autoFocus
            id="firstName"
            type="text"
            name="firstName"
            autoComplete="given-name"
            placeholder="John"
            invalid={!!props?.errors?.firstName}
            defaultValue={props?.defaults?.firstName}
          />
        </Field>
        <Field>
          <Label>
            <span className="pr-2">Last Name</span>
            {props?.errors?.lastName ? (
              <Badge color="red" className="text-justify opacity-80">
                ({props.errors.lastName.join(', ')})
              </Badge>
            ) : null}
          </Label>
          <Input
            required
            id="lastName"
            type="text"
            name="lastName"
            autoComplete="family-name"
            placeholder="Doe"
            invalid={!!props?.errors?.lastName}
            defaultValue={props?.defaults?.lastName}
          />
        </Field>
      </div>
      <Field className="mt-6">
        <Label>
          <span className="pr-2">Email</span>
          {props?.errors?.email ? (
            <Badge color="red" className="text-justify opacity-80">
              ({props.errors.email.join(', ')})
            </Badge>
          ) : null}
        </Label>
        <Input
          required
          id="email"
          type="email"
          name="email"
          autoComplete="email"
          placeholder="john.doe@uptimeinsight.com"
          invalid={!!props?.errors?.email}
          defaultValue={props?.defaults?.email}
        />
      </Field>

      <CheckboxField className="mt-8">
        <Checkbox value="true" id="consent" name="consent" className="self-start p-1" />
        <Label className="pointer-events-auto" htmlFor="consent">
          I agree to the{' '}
          <TextLink
            onClick={(e) => e.stopPropagation()}
            href="https://uptimeinsight.com/terms-and-conditions"
            target="_blank"
          >
            Terms and Conditions
          </TextLink>{' '}
          and{' '}
          <TextLink
            onClick={(e) => e.stopPropagation()}
            href="https://uptimeinsight.com/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </TextLink>
          {props?.errors?.consent ? (
            <Badge color="red" className="ml-2 block text-justify opacity-80">
              {props.errors.consent.join(', ')}
            </Badge>
          ) : null}
        </Label>
      </CheckboxField>
      <Captcha siteKey={props.siteKey} action="sign-up" />
    </>
  );
};

const InvitationForm = (props: Props) => {
  return (
    <>
      <div
        className={clsx(
          props?.errors?.[''] ? 'mt-6' : 'mt-8',
          'grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4'
        )}
      >
        <Field>
          <Label>
            <span className="pr-2">First Name</span>
            {props?.errors?.firstName ? (
              <Badge color="red" className="text-justify opacity-80">
                ({props.errors.firstName.join(', ')})
              </Badge>
            ) : null}
          </Label>
          <Input
            required
            autoFocus
            id="firstName"
            type="text"
            name="firstName"
            autoComplete="given-name"
            placeholder="John"
            invalid={!!props?.errors?.firstName}
            defaultValue={props?.defaults?.firstName}
          />
        </Field>
        <Field>
          <Label>
            <span className="pr-2">Last Name</span>
            {props?.errors?.lastName ? (
              <Badge color="red" className="text-justify opacity-80">
                ({props.errors.lastName.join(', ')})
              </Badge>
            ) : null}
          </Label>
          <Input
            required
            id="lastName"
            type="text"
            name="lastName"
            autoComplete="family-name"
            placeholder="Doe"
            invalid={!!props?.errors?.lastName}
            defaultValue={props?.defaults?.lastName}
          />
        </Field>
      </div>

      <CheckboxField className="mt-8">
        <Checkbox value="true" id="consent" name="consent" className="self-start p-1" />
        <Label className="pointer-events-auto" htmlFor="consent">
          I agree to the{' '}
          <TextLink
            onClick={(e) => e.stopPropagation()}
            href="https://uptimeinsight.com/terms-and-conditions"
            target="_blank"
          >
            Terms and Conditions
          </TextLink>{' '}
          and{' '}
          <TextLink
            onClick={(e) => e.stopPropagation()}
            href="https://uptimeinsight.com/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </TextLink>
          {props?.errors?.consent ? (
            <Badge color="red" className="ml-2 block text-justify opacity-80">
              {props.errors.consent.join(', ')}
            </Badge>
          ) : null}
        </Label>
      </CheckboxField>
      <Captcha siteKey={props.siteKey} action="accept-invite" />
    </>
  );
};

export default function SignForm(props: Props) {
  let Form = SignInForm;

  switch (props.type) {
    case SignType.SignUp:
      Form = SignUpForm;
      break;
    case SignType.Invitation:
      Form = InvitationForm;
      break;
  }

  return (
    <form method="POST" className="p-7 sm:p-11">
      {props.invitation && <input type="hidden" value={props.invitation} name="invitation" />}
      <input type="hidden" value={props.csrf} name="csrf" />
      <div className="flex items-start">
        <ShapeLogo className="block h-9 w-auto dark:hidden" />
        <ShapeLogo className="hidden h-9 w-auto dark:block" dark={'true'} />
      </div>
      <Strong className="mt-8 block">{messages[props.type].title}</Strong>
      <Text className="mt-1">{props.subtitle || messages[props.type].subTitle}</Text>

      {props.errors?.[''] ? (
        <Badge
          color="red"
          className="mt-8 w-full items-center justify-center text-center opacity-80"
        >
          {props.errors[''].join(', ')}
        </Badge>
      ) : null}

      {props?.otpEmail ? (
        <OtpForm email={props.otpEmail} errors={props.errors} />
      ) : (
        <Form {...props} />
      )}

      <div className="mt-8">
        <Button className="w-full cursor-pointer" type="submit">
          {messages[props.type].submit}
        </Button>
        {props.errors?.['cf-turnstile-response'] ? (
          <Badge
            color="red"
            className="mt-4 w-full items-center justify-center px-3 py-3 text-center opacity-80"
          >
            Failed to verify Captcha
          </Badge>
        ) : null}
      </div>
    </form>
  );
}
