import { SVGProps } from 'react';

export default function ShapeLogo(
  props: SVGProps<any> & { color?: string; dark?: 'true' | 'false' }
) {
  const color = props.color || (props.dark === 'true' ? '#FFFFFF' : '#18181b');
  return (
    <svg viewBox="0 0 606 488" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M274.14 8.01159C268.58 -2.55473 253.501 -2.69976 247.738 7.75756L1.87214 453.908C-3.3595 463.401 2.94009 475.14 13.7419 476.043C199.446 491.564 312.614 491.604 496.931 476.022C507.602 475.12 513.906 463.619 508.919 454.142L486.389 411.33C519.132 410.238 553.415 408.096 592.285 404.9C603.21 404.002 609.459 392.028 603.978 382.535L448.497 113.234C442.726 103.238 428.297 103.238 422.526 113.234L373.867 197.514L274.14 8.01159Z"
        fill={color}
      />
    </svg>
  );
}
