export enum SignType {
  Invitation,
  SignIn,
  SignUp
}

export interface Props {
  subtitle?: string;

  invitation?: string;
  otpEmail?: string;
  siteKey: string;
  type: SignType;
  csrf: string;

  errors?: {
    'cf-turnstile-response'?: string[];
    firstName?: string[];
    lastName?: string[];
    consent?: string[];
    email?: string[];
    otp?: string[];
    ''?: string[];
  };

  defaults?: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
}
